
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_goal } from "@/functions/Cl_goal";
import { DtoGoalsPutReq } from "furukawa-common/src/common/dto/abst/DtoGoalsPutReq";
import { DtoGoalsGetReqImple } from "furukawa-common/src/common/dto/DtoGoalsGetReqImple";
import { DtoGoalsGetResImple } from "furukawa-common/src/common/dto/DtoGoalsGetResImple";
import { DtoGoalsPostReq } from "furukawa-common/src/common/dto/abst/DtoGoalsPostReq";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { CONST_OP_YEAR } from "furukawa-common/src/common/constant/Constants";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    Mo_modal_alert,
    vSelect,
  },
})
export default class PersonalGoalEdit extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoGoalsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isEditMode = true;
  // domYear = { label: "" };
  domYear = "";
  domStaffId = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opGoal = CONST_OP_YEAR;
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
    await this.getMastersList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      // dtoMaster.getBranch = 1;
      // dtoMaster.delFlag4Branch = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        //this.delegate.c_client_company_type = 1;
        //this.delegate.c_client_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoGoalsGetReqImple();

      dto.c_goal_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_goal.get(dto);

      Cl_common.logger("delegate");
      Cl_common.logger(this.delegate);

      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_goal_id == null) {
        this.$router.push("/personalGoalList");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoGoalsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_goal_id = this.delegate.c_goal_id;
        dto.c_goal_flag_del = 1;
      } else {
        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoGoalsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_goal.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "データを削除しました。";
      } else {
        //更新処理：
        this.message4ModalSuccess = "データを更新しました。";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = Object.assign(new DtoGoalsPostReq(), this.delegate);
      dto.c_goal_flag_del = 0;

      // if (
      //   this.domYear != null &&
      //   this.domYear.label != null &&
      //   this.domYear.label != ""
      // ) {
      //   dto.c_goal_year = Number(this.domYear.label);
      //   Cl_common.logger("dto.c_goal_year");
      //   Cl_common.logger(dto.c_goal_year);
      // }

      //API実行
      //-------
      const res = await Cl_goal.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/personalGoalEdit",
        query: { id: res.c_goal_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_goal_id +
      "】" +
      this.delegate.c_goal_year +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
